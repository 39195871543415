<template>
  <div class="login-container">
    <a-row>
      <a-col :xs="0" :md="4" :sm="6" :lg="8" :xl="8"></a-col>
      <a-col :xs="24" :sm="16" :md="12" :lg="8" :xl="8">
        <div class="login-container-form">
          <div class="logo-area">
            <div class="logo-image-area">
              <img class="logo-image" src="@/assets/image/loginLogo.svg" />
            </div>
          </div>
          <!-- <div class="login-container-title">{{ title }}</div> -->
          <a-form :model="form" @submit="handleSubmit" @submit.prevent>
            <a-form-item :wrapper-col="{ span: 24 }">
              <a-input v-model:value="form.email" placeholder="帳號">
                <template v-slot:prefix>
                  <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 24 }">
              <a-input
                v-model:value="form.password"
                type="password"
                placeholder="密碼"
              >
                <template v-slot:prefix>
                  <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 24 }">
              <a-button
                type="primary"
                html-type="submit"
                :disabled="form.email === '' || form.password === ''"
              >
                {{ $t('LI001.login') }}
              </a-button>
            </a-form-item>
          </a-form>
          <div class="login-container-version">
            Version：{{ version }}({{ buildNumber }})
          </div>
        </div>
      </a-col>
    </a-row>
    <div class="login-container-tips">
      {{ $t('LI001.copyright') }}
      <!-- Copyright © 2021 如影優活股份有限公司 All Rights Reserved. -->
    </div>
  </div>
</template>
<script>
  import { dependencies, devDependencies } from '*/package.json'
  import { mapActions, mapGetters } from 'vuex'
  import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
  import { version, buildNumber } from '@/config'

  export default {
    name: 'Login',
    components: {
      UserOutlined,
      LockOutlined,
    },
    data() {
      return {
        form: {
          email: '',
          password: '',
        },
        redirect: undefined,
        dependencies: dependencies,
        devDependencies: devDependencies,
        version,
        buildNumber,
      }
    },
    computed: {
      ...mapGetters({
        logo: 'settings/logo',
        title: 'settings/title',
      }),
    },
    watch: {
      $route: {
        handler(route) {
          this.redirect = (route.query && route.query.redirect) || '/'
        },
        immediate: true,
      },
    },
    mounted() {
      // this.form.account = 'ironman@avengers.marvel.com'
      // this.form.password = 'iamironman'
      this.form.email = process.env.VUE_APP_DEFAULT_ACCOUNT_NAME
      this.form.password = process.env.VUE_APP_DEFAULT_ACCOUNT_PW
      /*  setTimeout(() => {
        this.handleSubmit()
      }, 3000) */
    },
    methods: {
      ...mapActions({
        login: 'user/login',
      }),
      handleRoute() {
        return this.redirect === '/404' || this.redirect === '/403'
          ? '/'
          : this.redirect
      },
      async handleSubmit() {
        await this.login({
          userInfo: this.form,
          errorMessage: this.$t('LI001.loginFail'),
        })
        await this.$router.push(this.handleRoute())
      },
    },
  }
</script>
<style lang="less">
  .login-container {
    //width: 100%;
    height: 100vh;
    background: #e5e5e5;
    //background: url('~@/assets/login_images/login_background.png');
    background-size: cover;
    .logo-area {
      display: flex;
      align-items: center;
      justify-content: center;
      .logo-image-area {
        width: 200px;
        height: 150px;
        margin-bottom: 20px;
        .logo-image {
          // max-width: 100%;
          // max-height: 100%;
          width: 200px;
          height: 150px;
          object-fit: contain;
        }
      }
    }
    .login-container-form {
      background: #ffffff;
    }
    &-form {
      position: relative;
      width: calc(100% - 40px);
      padding: 4vh;
      margin-top: calc((100vh - 380px) / 2);
      margin-right: 20px;
      margin-left: 20px;
      margin-bottom: 0px;
      background: rgba(255, 255, 255, 0.1);
      background-size: 100% 100%;
      border-radius: 10px;
      box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
    }
    &-hello {
      font-size: 32px;
      color: #fff;
    }
    &-title {
      margin-bottom: 30px;
      font-size: 26px;
      color: #000;
      text-align: center;
      line-height: 32px;
    }
    &-tips {
      position: fixed;
      bottom: @vab-margin;
      width: 100%;
      height: 40px;
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #4dbaa7;
    }
    &-version {
      color: #4dbaa7;
      font-size: 12px;
      float: right;
    }
    .ant-input {
      width: 100%;
      height: 30px;
    }
    .ant-btn {
      width: 100%;
      height: 45px;
      background: #4dbaa7;
      border-radius: 5px;
    }
  }
</style>
